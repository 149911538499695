import React, { useContext, useEffect, useState } from 'react';
import FileDownloadIcon from '@material-ui/icons/GetApp';
import { Box, CardMedia, createStyles, Link, makeStyles } from '@material-ui/core';
import Context from '../../context';
import {
  FileType,
  getDownloadSignedUrlForAttachment,
  getDownloadSignedUrlForDeviationsAttachment,
} from '../../helpers/upload-download';
import { useTranslation } from 'react-i18next';
import { Attachment, IndexDbAttachment } from '../../schemas';
import { isAudioFile, isImageFile, isVideoFile } from '../../helpers/allowedFileTypes';
import { useGetToken } from '../../hooks/useGetToken';
import {
  generateIndexedDBKey,
  getIndexedDBBlobObject,
  storedIndexedDBObjectType,
} from '../../helpers/indexedDB';
import { hasDataToSync } from '../../helpers/syncHelper';
const useStyles = makeStyles((theme) =>
  createStyles({
    previewImage: {
      width: '100%',
      height: '100%',
    },
    downloadLink: {
      margin: theme.spacing(1),
    },
  })
);
const PreviewFile = (props: {
  file: File | Attachment;
  fileType: FileType;
  networkNumber?: string;
  questionSetIdParam?: string;
  questionSequenceNumber?: number;
}): JSX.Element => {
  const { file, fileType } = props;
  let networkNumber = props.networkNumber;
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateIsLoading } = useContext(Context);
  const [fileToShow, setFileToShow] = useState<string | undefined>();
  const [getTokenFunction] = useGetToken();

  const getImageUrlFromIndexedDB = async (indexDBKey: string, filename: string) => {
    const blobObject = await getIndexedDBBlobObject(indexDBKey, filename);
    return URL.createObjectURL(blobObject);
  };

  useEffect(() => {
    if ((file as Attachment).location || (file as IndexDbAttachment).location) {
      (async () => {
        try {
          updateIsLoading(true);
          if (networkNumber) {
            const hasData = await hasDataToSync(networkNumber);
            if (hasData) {
              console.warn('Data to sync, ', hasData);
            }
          }
          if (navigator.onLine) {
            const accessToken = await getTokenFunction();

            const downloadSignedUrls =
              fileType === FileType.ATTACHMENT
                ? await getDownloadSignedUrlForAttachment({
                    jwtToken: accessToken,
                    files: [file as Attachment],
                  })
                : await getDownloadSignedUrlForDeviationsAttachment({
                    jwtToken: accessToken,
                    files: [file as Attachment],
                  });
            if (downloadSignedUrls && downloadSignedUrls.length > 0) {
              setFileToShow(downloadSignedUrls[0].signedUrl);
            }
          }
          if (!navigator.onLine) {
            //checkfor netwkr number undefined scsnerio
            if (
              (file as Attachment) &&
              (file as Attachment).location.includes('HANDOVER')
            ) {
              networkNumber = (file as Attachment).location.split('/')[0];
            }
            const basekey = generateIndexedDBKey(
              networkNumber,
              storedIndexedDBObjectType.BASE64SSTRING
            );
            const imageUrl = await getImageUrlFromIndexedDB(
              basekey,
              (file as Attachment).filename
            );
            setFileToShow(imageUrl);
          }
        } catch (e) {
          console.error('Error while downloading file', e);
        } finally {
          updateIsLoading(false);
        }
      })();
    } else {
      const fileUrl = URL.createObjectURL(file as File);
      setFileToShow(fileUrl);
    }
  }, []);

  const fileName: string = (
    (file as File).name || (file as Attachment).filename
  ).toLowerCase();

  const renderContent = (fileName: string) => {
    if (isImageFile(fileName))
      return (
        <img
          className={classes.previewImage}
          src={fileToShow}
          alt=""
          data-testid="preview-file"
        />
      );
    if (isVideoFile(fileName))
      return (
        <CardMedia
          component="video"
          image={fileToShow}
          autoPlay
          data-testid="preview-file"
        />
      );
    if (isAudioFile(fileName))
      return (
        <CardMedia
          component="audio"
          image={fileToShow}
          autoPlay
          data-testid="preview-file"
        />
      );

    return t('uploadWidget.noPreview');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="end">
      {renderContent(fileName)}
      <Link
        className={classes.downloadLink}
        download={fileName}
        href={fileToShow}
        color="primary"
        aria-label="download-file"
        data-testid="download-link"
      >
        <FileDownloadIcon />
      </Link>
    </Box>
  );
};

export default PreviewFile;
