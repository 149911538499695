import { API_TYPE, put } from './fetch';
import {
  ActivityDifferentiator,
  InstallationSyncData,
  Syncpoint,
  Installation,
  Attachment,
} from '../schemas';
import {
  generateIndexedDBKey,
  storedIndexedDBObjectType,
  getIndexedDBObject,
  upsertIndexedDBObject,
  deleteIndexedDBObject,
} from './indexedDB';
import { base64ToBlob } from './base64ToBlob';
import {
  extractQuestionIdAndSequence,
  extractTimestamp,
} from './extractQuestionIdAndSequenceTime';

export const hasDataToSync = async (networkNumber: string): Promise<boolean> => {
  const pending = await getIndexedDBObject(
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
  return Boolean(pending);
};

export const setHasDataToSync = async (networkNumber: string): Promise<void> => {
  await upsertIndexedDBObject(
    true,
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
};

export const removeHasDataToSync = async (networkNumber: string): Promise<void> => {
  await deleteIndexedDBObject(
    generateIndexedDBKey(networkNumber, storedIndexedDBObjectType.HAS_DATA_TO_SYNC)
  );
};

const APIGW_URL = process.env.REACT_APP_APIGW_URL || '';
export const syncWithBackend = async (
  networkNumber: string,
  jwtToken: string
): Promise<void> => {
  try {
    const syncDataKey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.SYNC_DATA
    );

    const syncDatafromIdxDb = await getIndexedDBObject<InstallationSyncData>(syncDataKey);
    if (syncDatafromIdxDb) {
      await getImageData(syncDatafromIdxDb, jwtToken, networkNumber);
    }
  } catch (error) {
    throw new Error(`Error while syncing with backend ${error}`);
  }
};

export const cacheNetworkWithInstallationData = async (
  installationData: Installation,
  networkNumber: string,
  jwtToken: string,
  userRole: ActivityDifferentiator
): Promise<void> => {
  const installationDatakey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.NETWORK
  );
  await upsertIndexedDBObject(installationData, installationDatakey);
  const syncpointResponse: Syncpoint = await put(
    `v1/installations/${networkNumber}/syncpoint`,
    jwtToken,
    API_TYPE.APPLICATION,
    {
      userRole: userRole,
    }
  );
  return saveSyncpointindexDB(networkNumber, syncpointResponse);
};

export const saveSyncpointindexDB = async (
  networkNumber: string,
  syncpoint: Syncpoint
): Promise<void> => {
  const syncpointkey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.SYNC_POINT
  );
  return await upsertIndexedDBObject(syncpoint, syncpointkey);
};

export const getImageData = async (
  syncDatafromIdxDb: any,
  jwtToken: any,
  networkNumber: any
) => {
  // const [updateAnswerInContext] = useUpdateAnswerInContext();
  const attachmentParam: Attachment[] = [];
  try {
    const syncPointKey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.SYNC_POINT
    );
    const base64IndexDbkey = generateIndexedDBKey(
      networkNumber,
      storedIndexedDBObjectType.BASE64SSTRING
    );
    if (typeof syncDatafromIdxDb === 'object' && syncDatafromIdxDb !== null) {
      const newsyncDatafromIdxDb = { ...syncDatafromIdxDb }; // Create a copy of the object

      if (Array.isArray(newsyncDatafromIdxDb.answers)) {
        // Use map and Promise.all to handle asynchronous operations
        await Promise.all(
          newsyncDatafromIdxDb.answers.map(async (answer: any) => {
            if (typeof answer === 'object' && answer !== null && 'value' in answer) {
              if (Array.isArray(answer.value)) {
                const base64dataArray: any[] = (await getIndexedDBObject(
                  base64IndexDbkey
                )) as any[];
                await Promise.all(
                  answer.value.map(async (item: any, index: any) => {
                    if (
                      typeof item === 'object' &&
                      item !== null &&
                      'filename' in item &&
                      'toBeSynced' in item &&
                      item.toBeSynced === true
                    ) {
                      console.warn(`Processing item at position ${index}:`, item);
                      //extracting the base64 data from the indexedDB for the file that we are syncing
                      const base64Data = base64dataArray.find((base64Item: any) => {
                        const locationData = extractQuestionIdAndSequence(item.location);
                        return (
                          base64Item.filename === item.filename &&
                          locationData &&
                          locationData.questionId === base64Item.questionId &&
                          locationData.sequenceNumber === base64Item.sequenceNumber
                        );
                      });
                      try {
                        // Validate item structure
                        if (!base64Data && !base64Data.base64Data) {
                          throw new Error(
                            `Invalid blob structure for item at position ${index}`
                          );
                        }
                        if (base64Data.base64Data && item.toBeSynced === true) {
                          const blob = base64ToBlob(
                            base64Data.base64Data,
                            base64Data.mimeType
                          );
                          if (!(blob instanceof Blob)) {
                            throw new Error('base64ToBlob did not return a valid Blob');
                          }
                          const filenames = base64Data.filename;
                          const resp = await getUploadSignedUrl(
                            filenames,
                            jwtToken,
                            networkNumber,
                            answer.questionSetId,
                            answer.questionSequenceNumber
                          );
                          if (!resp.ok) {
                            throw new Error(
                              `Failed to fetch signed URL, status: ${resp.status}`
                            );
                          }
                          const data = await resp.json();
                          try {
                            if (typeof data[0].signedUrl === 'string') {
                              const requestOptions = {
                                method: 'PUT',
                                body: blob,
                              };
                              await fetch(`${data[0].signedUrl}`, requestOptions);
                              // await axios.put(data[0].signedUrl, blob);
                            } else {
                              console.error('signedUrl is not a string');
                            }

                            // Update the item with new values from response
                            item.filename = data[0].originalFilename;
                            item.location = data[0].locationFilename;
                            item.toBeSynced = false;
                            const timestamp = await extractTimestamp(base64Data.filename);
                            // Push updated item to attachmentParam
                            attachmentParam.push({
                              filename: base64Data.filename,
                              location: base64Data.location,
                              timestamp: timestamp,
                              toBeSynced: false,
                            });
                          } catch (error) {
                            console.error(`Error fetching image from S3: ${error}`);
                            throw error;
                          }
                        }
                        console.warn(
                          `Item at position ${index} processed successfully`,
                          item
                        );
                      } catch (error) {
                        console.error(
                          `Error processing item at position ${index}:`,
                          error
                        );
                      }
                    } else {
                      console.warn(
                        `Item at position ${index} is not valid or missing 'filename':`,
                        item
                      );
                    }
                  })
                );
              }
            }
          })
        );

        // Proceed with the updated newsyncDatafromIdxDb
        const requestOptionsforSync = {
          method: 'PUT',
          headers: {
            Authorization: jwtToken,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newsyncDatafromIdxDb),
        };
        try {
          const syncDataKey = generateIndexedDBKey(
            networkNumber,
            storedIndexedDBObjectType.NETWORK
          );
          const response = await fetch(
            `${APIGW_URL}/v1/installations/${networkNumber}/sync`,
            requestOptionsforSync
          );
          if (!response.ok) {
            throw new Error(`Failed to sync data, status: ${response.status}`);
          }
          const syncDatakeyIndex = generateIndexedDBKey(
            networkNumber,
            storedIndexedDBObjectType.SYNC_DATA
          );
          const syncPoint = await response.json();
          const requestOptions = {
            method: 'GET',
            headers: {
              Authorization: jwtToken,
              'Content-Type': 'application/json',
            },
          };
          if (syncPoint) {
            await upsertIndexedDBObject(syncPoint, syncPointKey);
            await upsertIndexedDBObject(newsyncDatafromIdxDb, syncDataKey);
            await removeHasDataToSync(networkNumber);
            await deleteIndexedDBObject(syncDataKey);
            await deleteIndexedDBObject(syncDatakeyIndex);
            const response = await fetch(
              `${APIGW_URL}/v1/installations/${networkNumber}`,
              requestOptions
            );
            if (!response.ok) {
              throw new Error(`Failed to GET N/W data, status: ${response.status}`);
            }
            window.location.reload();
          }
        } catch (error) {
          console.error('Error during sync process:', error);
        }

        return newsyncDatafromIdxDb;
      }
    }
    return false;
  } catch (e) {
    console.error('error:::', e);
  }

  // The object does not meet the condition
};
export const getUploadSignedUrl = async (
  filenames: any,
  jwttoken: any,
  networkNumber: any,
  questionSetIdParam: any,
  questionSequenceNumber: any
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: jwttoken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ filenames: filenames }),
  };
  return await fetch(
    `${APIGW_URL}/v1/attachments/${networkNumber}/${questionSetIdParam}/${Number(
      questionSequenceNumber
    )}/upload`,
    requestOptions
  );
};
