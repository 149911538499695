import { Attachment, IndexDbAttachmentParam, ProcessImageDataParams } from '../schemas';
import {
  FileType,
  convertBlobToBase64,
  getDownloadSignedUrlForAttachment,
  getDownloadSignedUrlForDeviationsAttachment,
} from '../helpers/upload-download';

export const processImageValue = async ({
  accessToken,
  indexDbattachmentParamArray,
  fileType,
  data,
  deviationData,
  handoverData,
}: ProcessImageDataParams): Promise<IndexDbAttachmentParam[]> => {
  const promises: Promise<void>[] = [];
  if (fileType === FileType.ATTACHMENT && data) {
    const attachmentPromises = (data?.testerAnswers ?? []).map(async (answerSet) => {
      const answerPromises = answerSet.answers.map(async (answer) => {
        if (answer?.value) {
          if (Array.isArray(answer.value)) {
            // Iterate through the answer value (array of attachments)
            for (const obj of answer.value) {
              const attachment = obj as Attachment;
              if (!attachment.filename) continue; // Skip if no filename

              try {
                const url = await getDownloadSignedUrlForAttachment({
                  jwtToken: accessToken,
                  files: [attachment],
                });
                const requestOptions = { method: 'GET' };
                const response = await fetch(url[0].signedUrl, requestOptions);

                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const arrayBuffer = await response.arrayBuffer();
                const blob = new Blob([arrayBuffer], {
                  type: MimeType.toString(), // Ensure MimeType is correctly defined
                });
                const base64 = await convertBlobToBase64(blob);
                const questionId = answer.questionSetId || '';

                indexDbattachmentParamArray.push({
                  filename: attachment.filename,
                  location: attachment.location,
                  timestamp: attachment.timestamp,
                  questionId: questionId,
                  sequenceNumber: answer.questionSequenceNumber,
                  base64Data: base64 as string,
                  mimeType: MimeType.toString(),
                  tobeSynced: false,
                  fileType: FileType.ATTACHMENT,
                });
              } catch (error) {
                console.error('Error processing attachment:', error);
              }
            }
          }
        }
      });

      // Wait for all the answerPromises to resolve
      await Promise.all(answerPromises);
    });

    promises.push(...attachmentPromises);
  } else if (fileType === FileType.DEVIATION) {
    const deviationPromises = (deviationData ?? []).map(async (deviation) => {
      if (deviation.history) {
        const historyPromises = deviation.history.map(async (item) => {
          if (item.files) {
            const filePromises = item.files.map(async (file) => {
              // Logic for processing files related to deviations
              const QuestionData = {
                questionId: deviation.questionSetId || '',
                sequenceNumber: deviation.questionSequence || 0,
              };
              if ((file as Attachment).filename) {
                const url = await getDownloadSignedUrlForDeviationsAttachment({
                  jwtToken: accessToken,
                  files: [file as Attachment],
                });
                const requestOptions = { method: 'GET' };
                const response = await fetch(url[0].signedUrl, requestOptions);
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // convert file to blob
                if ((file as Attachment).filename) {
                  const attachment = file as Attachment;
                  const arrayBuffer = await response.arrayBuffer();
                  const blob = new Blob([arrayBuffer], {
                    type: MimeType.toString(),
                  });
                  const mimeType = MimeType.toString();
                  const base64 = await convertBlobToBase64(blob);
                  indexDbattachmentParamArray.push({
                    filename: attachment.filename,
                    location: attachment.location,
                    timestamp: attachment.timestamp,
                    questionId: QuestionData.questionId,
                    sequenceNumber: QuestionData.sequenceNumber,
                    base64Data: base64 as string,
                    mimeType:mimeType,
                    tobeSynced: false,
                    fileType: FileType.DEVIATION,
                  });
                }
              }

              // Further file processing logic can go here
            });
            // Wait for all filePromises to resolve
            await Promise.all(filePromises);
          }
        });

        // Wait for all historyPromises to resolve
        await Promise.all(historyPromises);
      }
    });

    promises.push(...deviationPromises);
  } else if (fileType === FileType.ATTACHMENT && handoverData) {
    const attachmentPromises = (handoverData?.attachments ?? []).map(
      async (attachment) => {
        try {
          const url = await getDownloadSignedUrlForAttachment({
            jwtToken: accessToken,
            files: [attachment],
          });
          const requestOptions = { method: 'GET' };
          const response = await fetch(url[0].signedUrl, requestOptions);

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const arrayBuffer = await response.arrayBuffer();
          const blob = new Blob([arrayBuffer], {
            type: MimeType.toString(), // Ensure MimeType is correctly defined
          });
          const base64 = await convertBlobToBase64(blob);
          const questionId = 'HANDOVER';
          indexDbattachmentParamArray.push({
            filename: attachment.filename,
            location: attachment.location,
            timestamp: attachment.timestamp,
            questionId: questionId,
            sequenceNumber: 0,
            base64Data: base64 as string,
            mimeType: MimeType.toString(),
            tobeSynced: false,
            fileType: FileType.ATTACHMENT,
          });
        } catch (error) {
          console.error('Error processing attachment:', error);
        }
      }
    );
    promises.push(...attachmentPromises);
  }
  // Wait for all promises to resolve
  await Promise.all(promises);
  return indexDbattachmentParamArray;
};
