import React from 'react';
import { createStyles, Grid, Link, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    assigmentInfo: {
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      '& p': {
        cursor: 'pointer',
      },
      textTransform: 'uppercase',
    },
  })
);
export type AddButtonProps = {
  label: string;
  onClick: () => void;
};
const AddButton = (props: AddButtonProps): JSX.Element => {
  const classes = useStyles();
  const { label, onClick } = props;
  return (
    <>
      <Grid container className={classes.assigmentInfo}>
        <Grid item xs={12}>
          <Typography>
            <Link onClick={onClick}>{label}</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AddButton;
