/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import {
  Contact,
  Employee,
  Installation,
  InstallationStatus,
  SubcontractorRecord,
  Vendor,
} from '../schemas';

export enum API_TYPE {
  APPLICATION,
  ANALYTICS,
  FORM_BUILDER,
}

export const APIGW_URL = process.env.REACT_APP_APIGW_URL || '';
export const ANALYTICS_API_URL = process.env.REACT_APP_ANALYTICS_API_URL || '';
export const FORM_BUILDER_API_URL = process.env.REACT_APP_FORM_BUILDER_API_URL || '';

export const getBaseUrl = (api: API_TYPE) => {
  switch (api) {
    case API_TYPE.APPLICATION: {
      return APIGW_URL;
    }
    // return APIGW_URL;
    case API_TYPE.ANALYTICS:
      return ANALYTICS_API_URL;
    case API_TYPE.FORM_BUILDER:
      return FORM_BUILDER_API_URL;
  }
};

export const get = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.APPLICATION,
  params?: object
): Promise<any> => {
  try {
    const baseURL = getBaseUrl(api);
    const { data } = await axios.get(`${baseURL}/${url}`, {
      params,
      headers: {
        Authorization: token,
      },
    });

    return data;
  } catch (error) {
    console.error(error);

    return Promise.resolve(null);
  }
};

export const getFormBuilder = async (
  url: string,
  accessToken: string,
  api: API_TYPE = API_TYPE.FORM_BUILDER,
  params?: object
): Promise<any> => {
  try {
    const baseURL = getBaseUrl(api);
    const { data } = await axios.get(`${baseURL}/${url}`, {
      params,
      headers: {
        Authorization: accessToken,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return Promise.resolve(null);
  }
};

export const post = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.APPLICATION,
  params?: object
): Promise<any> => {
  const baseURL = getBaseUrl(api);
  const { data } = await axios.post(`${baseURL}/${url}`, params, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

export const put = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.APPLICATION,
  params?: object
): Promise<any> => {
  const baseURL = getBaseUrl(api);
  const { data } = await axios.put(`${baseURL}/${url}`, params, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

export const remove = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.APPLICATION
): Promise<any> => {
  const baseURL = getBaseUrl(api);
  const { data } = await axios.delete(`${baseURL}/${url}`, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

export const patch = async (
  url: string,
  token: string,
  api: API_TYPE = API_TYPE.APPLICATION,
  params?: object
): Promise<any> => {
  const baseURL = getBaseUrl(api);

  const { data } = await axios.patch(`${baseURL}/${url}`, params, {
    headers: {
      Authorization: token,
    },
  });

  return data;
};

/*##### Helper functions #####*/
const memoizedEmployees: { [employeeId: string]: Employee } = {};

/**
 * A helper function
 * @param employeeId
 * @param jwtToken
 * @returns Employee data
 */
export const fetchEmployee = async (
  employeeId: number | string | undefined | null,
  jwtToken: string
): Promise<Employee | null> => {
  if (employeeId === null || employeeId === undefined) return null;
  const memoEmployee = memoizedEmployees[employeeId];
  if (memoEmployee) return memoEmployee;
  const fetchedEmployees = await get(`v1/employees?employeeId=${employeeId}`, jwtToken);

  if (fetchedEmployees && fetchedEmployees.length > 0) {
    memoizedEmployees[employeeId] = fetchedEmployees[0];
    return fetchedEmployees[0];
  }

  return null;
};

/**
 * A helper function
 * @param employeeId
 * @param accessToken
 * @returns Employee first name or last name or full name or employeeId
 */
export const fetchEmployeeFullName = async (
  employeeId: string,
  accessToken: string
): Promise<string> => {
  const fetchedEmployee = await fetchEmployee(employeeId, accessToken);
  if (fetchedEmployee) {
    const firstName = fetchedEmployee?.legalFirstName ?? '';
    const lastName = fetchedEmployee?.legalLastName ?? '';

    return `${firstName} ${lastName}`.trim();
  }
  return employeeId;
};

/**
 * A helper function
 * @param accessToken
 * @param installation
 * @returns All employees data from specific installation
 */
export const getEmployeesDataFromInstallation = async (
  accessToken: string,
  installation: Installation
): Promise<(Employee | null)[]> => {
  const assigneeIds = installation.assignees.map((assigne) => {
    return assigne.koneResourcePersonalNumber;
  });
  const employeeIds = [installation.supervisorNumber, ...assigneeIds];
  const employeesDataPromise = employeeIds.map((employeeId) =>
    fetchEmployee(employeeId, accessToken)
  );
  return await Promise.all(employeesDataPromise);
};

/**
 * A helper function for validating subcontractor login
 * @returns Success/Error Message
 */
type SubcontractorLoginResponse = {
  token: string;
  message: string;
  status:
    | InstallationStatus.FOR_INSTALLER_ACCEPTANCE
    | InstallationStatus.INSTALLER_ACCEPTED
    | InstallationStatus.FOR_TESTER_ACCEPTANCE
    | InstallationStatus.TESTER_ACCEPTED;
};

export const subcontractorLoginPost = async (
  url: string,
  params?: object
): Promise<SubcontractorLoginResponse> => {
  const { data } = await axios.post(`${APIGW_URL}/${url}`, params);
  return data as SubcontractorLoginResponse;
};

/**
 * A helper function
 * @param networkNumber
 * @param accessToken
 * @returns Subcontractors data
 */
export const fetchSubcontractors = async (
  networkNumber: string,
  accessToken: string
): Promise<SubcontractorRecord[]> => {
  return await get(
    `v1/subcontractor/${networkNumber}/access`,
    accessToken,
    API_TYPE.APPLICATION
  );
};

export const fetchVendors = async (
  countryCode: string,
  accessToken: string
): Promise<Vendor[]> => {
  return await get(`v1/vendors/${countryCode}`, accessToken);
};

export const fetchContacts = async (
  networkNumber: string,
  accessToken: string
): Promise<Contact[]> => {
  return await get(`v1/installations/${networkNumber}/contacts`, accessToken);
};
